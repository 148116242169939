export const enhancer = el => {

  const language = document.querySelector('html').getAttribute('lang');

  let url = 'https://admin.mokumboot.nl/en/calculator/';
  if (language === 'nl-NL') {
    url = 'https://admin.mokumboot.nl/calculator/';
  }

  if (language === 'fr-FR') {
    url = 'https://admin.mokumboot.nl/fr/calculator/';
  }

  if (language === 'de-DE') {
    url = 'https://admin.mokumboot.nl/de/calculator/';
  }

  if (window.location.hostname === "localhost" || window.location.hostname === "mokumboot.localhost") {
    url = '/calculator/';
  }

  const queryString = window.location.search;
  console.log(queryString);

  if (queryString) {
    url += queryString;
  }

  console.log(url);

  // create meta
  const meta = document.createElement('meta');
  meta.setAttribute('name', 'viewport');
  meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');

  // create iframe
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', url);
  iframe.setAttribute('height', '400px');
  iframe.classList.add('calculator--charter');
  el.appendChild(iframe);
};
